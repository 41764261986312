import { useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import BasicTable from '../utils/BasicTable';
import ImgNumberOne from '../assets/1.png';
import ImgNumberTwo from '../assets/2.png';
import ImgNumberThree from '../assets/3.png';
import ImgNumberFour from '../assets/4.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import BGgif from '../assets/input-bg-2.gif';
import Categories from '../utils/Categories';
import Resources from '../utils/Resources';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function InputAndQuestionForm() {

    const { t } = useTranslation();
    const [currentQuestion, setCurrentQuestion] = useState(0);
	const [results, setResults] = useState([]);
    const data = [];
    const [extractedData, setExtractedData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [localDB, setLocalDB] = useState({});
    const [open, setOpen] = React.useState(false);
    const ref = useRef(null);
    const [repetitiveQuestionIDs, setRepetitiveQuestionIDs] = useState([]);
    const [valueCollectorWithID, setValueCollectorWithID] = useState([]);
    const [currentLanguageDir, setCurrentLanguageDir] = useState('rtl');

    

    function resetAll() {
        setExtractedData([]);
        setLocalDB({});
        setLoading(true);
        setCurrentQuestion(0);
        setResults([]);
        setValueCollectorWithID([]);
        setRepetitiveQuestionIDs([]);
    }
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const resetAndhandleClose = () => {
      setOpen(false);
      resetAll();
    };    

   

    function areYouSureModal() {
        handleClickOpen(true)
    }

    function Introduction() {
        return(
            <IntroBox>
                <TextIntro>
                    <HeadingIntro>{t('introduction.fourEasyStepsHeading')}</HeadingIntro>
                    <ParagraphIntro>{t('introduction.introductionDetails')}</ParagraphIntro>
                </TextIntro>
                <ImgNumberContainer>
                    <ImgNumberBox>
                        <ImgNumber src={ImgNumberOne} alt="number one" />
                        <TextDiv>Link</TextDiv>
                    </ImgNumberBox>
                    <ImgNumberBox>
                        <TextDiv>Questions</TextDiv>
                        <ImgNumber src={ImgNumberTwo} alt="number two" />
                    </ImgNumberBox>
                    <ImgNumberBox>
                        <TextDiv>Results</TextDiv>
                        <ImgNumber src={ImgNumberThree} alt="number three" />
                    </ImgNumberBox>
                    <ImgNumberBox>
                        <TextDiv>Advices</TextDiv>
                        <ImgNumber src={ImgNumberFour} alt="number four" />
                    </ImgNumberBox>
                </ImgNumberContainer>
            </IntroBox>
        )
    }

    function stateInitilizer(fetchedData) {
        // Initilizes the state based on the contexts
        // This function should run only one time
        let initializedState = {};
        if (fetchedData.length > 0) {
            fetchedData.forEach((item) => {
                if ((!initializedState.hasOwnProperty(item.context) && item.category === 'advice') || (!initializedState.hasOwnProperty(item.context) && item.category === 'پیشنهادات' )) {
                    initializedState[item.context] = [[], [], [], [item.option1, item.option2, item.option3, item.option4, item.option5]];
                }
            });    
        }
        setLocalDB(initializedState)
    }




    function InputForUrl() {
        // FORM component
        // Creating an object based on the tables in Google-Sheet
        const [inputUrl, setInputUrl] = useState('');
        const [currSheetID, setCurrSheetID] = useState(''); 
        
        function handleAssessmentID(link) {
            setInputUrl(link)
        }
       
        function dataHelper(newData) {
            const jsData = JSON.parse(newData.slice(47,-2));
            const colz = [];
            jsData.table.cols.forEach((heading)=>{
                if(heading.label) {
                colz.push(heading.label.toLowerCase().replace(/\s/g,''));
                }
            })
            jsData.table.rows.forEach((main)=>{
                const row = {};
                colz.forEach((ele,ind)=>{
                    row[ele] = (main.c[ind] != null) ? main.c[ind].v : '';
                })
                data.push(row);
                stateInitilizer(data)

                const english = /^[A-Za-z0-9]*$/;
                
                if (english.test(data[0].context[0])) {
                    // for English language filteres advice and numbers
                    let filteredData = data.filter(item => !Number(item.question) && item.category !== 'advice')
                    setExtractedData(filteredData);
                    let repetitiveQuestions = data.filter(item => Number(item.question));
                    setRepetitiveQuestionIDs(repetitiveQuestions);
                    setCurrentLanguageDir('ltr')
                } else {
                    // for Farsi language filteres advice and numbers
                    let filteredData = data.filter(item => !Number(item.question) && item.category !== 'پیشنهادات')
                    setExtractedData(filteredData)
                    let repetitiveQuestions = data.filter(item => Number(item.question));
                    setRepetitiveQuestionIDs(repetitiveQuestions);
                    setCurrentLanguageDir('rtl')
                }
            })
        }
        

        function fetchFunc(newSheetID) {
            // Fetching the URL
            resetAll();
            const loadPost = async () => { 
                const base = `https://docs.google.com/spreadsheets/d/${newSheetID}/gviz/tq?`;
                const query = encodeURIComponent('Select *');
                const url = `${base}&sheet=&tq=${query}`;
                const data = [];
                await axios.get(url)
                .then(res => dataHelper(res.data))
                setLoading(false); 
            }
            loadPost();
            setInputUrl('')
        }



        function handleSubmit(evt) {
            // Finished input validation on Basic level, accepts only links that starts with google-sheet URL and disabled on empty
            evt.preventDefault();
            const inputUrlText = 'https://docs.google.com/spreadsheets'
            const incomingInput = inputUrl.split('/d/')
            const expectedInputUrl = incomingInput[0];
            if (inputUrlText === expectedInputUrl) {
                const segments = inputUrl.split('/');
                const newSheetID = segments[5] || segments.pop(); 
                setCurrSheetID(newSheetID)
                fetchFunc(newSheetID);
                ref.current?.scrollIntoView({behavior: 'smooth'});
            } else if (inputUrl === '') {
            } else {
                alert("insert correct link")
                resetAll();
            }
        }

        return (
            <InputBox>
                <LeftContainer>
                    <H4>{t('inputAndQuestionForm.useOurAssessmentMsg')}</H4>
                    <Categories handleAssessmentID={handleAssessmentID} handleSubmit={handleSubmit}/>
                </LeftContainer>
                <RightContainer>
                    <BackgroundGif src={BGgif} alt="background gif"/>
                    <FormBox>
                        <HeadingTextBox>
                            <P>{t('inputAndQuestionForm.assessYourRisk')}</P>
                            <H2><Span>my</Span>RiskGuru</H2> 
                        </HeadingTextBox>
                        <Form onClick={handleSubmit} name="form">
                            <TextField
                                required
                                id="filled-helperText"
                                label={t('inputAndQuestionForm.InputPlaceholder')}
                                helperText={t('inputAndQuestionForm.fileAccessPermissionGuide')}
                                position="center"
                                name="url"
                                onChange={e => setInputUrl(e.target.value)}
                                value={inputUrl}
                                />
                            <Button 
                                disabled={inputUrl === ''} 
                                type="submit" 
                                value="Submit" 
                                variant="contained"
                                style={{ height: '40px' }}
                                >
                                {t('inputAndQuestionForm.submitButton')}
                            </Button>
                            <Button variant="outlined" color="error" style={{ marginTop: '10px'}} onClick={() => areYouSureModal()}>
                                {t('inputAndQuestionForm.resetButton')}
                            </Button>
                            <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                            >
                                <DialogTitle>{t('inputAndQuestionForm.allDataWillWillBeLostMsg')}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-slide-description">
                                    {t('inputAndQuestionForm.areYouSureMsg')}
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>Cancel</Button>
                                    <Button onClick={resetAndhandleClose}>Reset</Button>
                                </DialogActions>
                            </Dialog>
                        </Form>
                    </FormBox>
                </RightContainer>
            </InputBox>
        )
    }

    function bubbleSort(endResultArray) {
        for (var i = 0; i < endResultArray.length; i++) {
            for (var j = 0; j < (endResultArray.length - i - 1); j++) {
                if (endResultArray[j][0] < endResultArray[j + 1][0]) {
                    var temp = endResultArray[j]
                    endResultArray[j] = endResultArray[j + 1]
                    endResultArray[j + 1] = temp
                }
            }
        }
        return endResultArray;
      }
    

    function advisorHelper(resultsArray) {
        // Example: if Risk vakue is #2 and controls in place is #2 =>  out of 5 advices we advice control #3 and #4 to go above the risk
        // Risk status shows the gap bewtween the Risk profile and controls in place, the more the gap is, the more critical it becomes
        // Sort the Result Array based on Risk Status, displays the most critical at the first/top

        let endResults = [];
        for (let i = 0; i < resultsArray.length; i++) {
        let currentContext = resultsArray[i];
        
        let key = currentContext[0];
        let calculatedRisk = currentContext[1];
        let currentControl = currentContext[2];
        let adviceArray = currentContext[3];
        let riskStatus = calculatedRisk - currentControl;

        if (riskStatus <= -2) {
            // risk is acceptable
            endResults.push([riskStatus, key, [adviceArray[0]]]);
        } else if (riskStatus > -2 && riskStatus <= 1) {
            // risk is mid level
            if (adviceArray[2] !== "") {
                endResults.push([riskStatus, key, [adviceArray[2]]]);
            } else {
                endResults.push([riskStatus, key, [adviceArray[4]]]);
            }
        } else {
            // risk is high
            endResults.push([riskStatus, key, [adviceArray[4]]]);
        }
    }
        let sortedResults = bubbleSort(endResults)
        setResults(sortedResults);
    }
    
    function arraySumMaker(array) {
        let sum = 0;  
        for(let i = 0; i < array.length; i++){
                sum += array[i][1];
            }
        return (sum / array.length);
    }

    function createResults() {
        // This function will sum up all the values in likelihood and impact and control to calculate the criticality of the risk context
        // Pushee all the advices to advisorHelper() and that function decides what to display based on the risk status
        let currentInput = localDB;
        let currentResults = [];
        if (currentInput) {
            for (const [key, value] of Object.entries(currentInput)) {
                let currentProbability = arraySumMaker(value[0]);
                let currentImpact = arraySumMaker(value[1]);
                let currentControl = arraySumMaker(value[2]);
                let calculatedRisk = Math.ceil(currentProbability + currentImpact) / 2;
                currentResults.push([key, calculatedRisk, currentControl, value[3]])
            }
        }
        advisorHelper(currentResults)
    }

    

    function stateUpdater(localDB, value, context, category, id) {
        // Pushed all the advices to the state before the test starts
        // Values will be pushed into empty arrays for prob, impact and controls based on their category (used stateInitilizer())
        let currentState = localDB;
        if (currentState.hasOwnProperty(context)) {            
            if (category === 'probability' || category === 'افزایش احتمال') {
                let copyOfState = localDB;
                copyOfState[context][0].push([id, value])
                setLocalDB(copyOfState)
            } else if (category === 'impact' || category === 'افزایش آسیب'){
                let copyOfState = localDB;
                copyOfState[context][1].push([id, value])
                setLocalDB(copyOfState)
            } else {
                let copyOfState = localDB;
                copyOfState[context][2].push([id, value])
                setLocalDB(copyOfState)
            }
        } 
    }

    function localDBHelper (value, context, category, id) {
        stateUpdater(localDB, value, context, category, id);
    }
    
      
	function handleAnswerOptionClick(value, context, category, id){

        let copyOfCurrentState = valueCollectorWithID;
        for (let i = 0; i < repetitiveQuestionIDs.length; i++) {
            if (repetitiveQuestionIDs[i].question == id) {
                copyOfCurrentState.push([repetitiveQuestionIDs[i].context, repetitiveQuestionIDs[i].category, repetitiveQuestionIDs[i].question, [id, value]])
                setValueCollectorWithID(copyOfCurrentState);
            }
        }

        if (value === 1) {
            localDBHelper(value, context, category, id)
        } else if (value === 2) {
            localDBHelper(value, context, category, id)
        } else if (value === 3) {
            localDBHelper(value, context, category, id)
        } else if (value === 4) {
            localDBHelper(value, context, category, id)
        } else {
            localDBHelper(value, context, category, id)
        }


		const nextQuestion = currentQuestion + 1;
		if (nextQuestion < extractedData.length) {
            setCurrentQuestion(nextQuestion);
		} else {
            let currentDB = localDB;

            for (let i = 0; i < valueCollectorWithID.length; i++) {

                let thisItem = valueCollectorWithID[i]
                
                Object.entries(currentDB).forEach(([key, value]) => {
                    if (key == thisItem[0]) {

                        if (thisItem[1] === 'probability' || thisItem[1] === 'افزایش احتمال' ) {
                                currentDB[key][0].push(thisItem[3])
                            } else if (thisItem[1] === 'impact' || thisItem[1] === 'افزایش آسیب'){
                                currentDB[key][1].push(thisItem[3])
                            } else {
                                currentDB[key][2].push(thisItem[3])
                            }
                    }  
                })
            }
            setLocalDB(currentDB);
            setLoading(true);
            setExtractedData([]);
            createResults();
		}    
    }

    return (
        <LandingSection>
            <InputForUrl />
            <QuestionAndAnswers>
                <GuideBox>
                    <Resources />
                </GuideBox>
                <ContentBox>
                    {loading ? (
                        <ResultSection>
                            {results.length ? <BasicTable results={results}/> : <Introduction />}
                        </ResultSection>
                    ) : (
                        <>
                            <FormContainer ref={ref}>
                                <QuestionSection>
                                    <QuestionCount>
                                        <span>Question {currentQuestion + 1}</span>/
                                        {extractedData.length}
                                    </QuestionCount>
                                    <Context style={{direction: currentLanguageDir}}>{extractedData && extractedData[currentQuestion].context}</Context>
                                    <Category style={{direction: currentLanguageDir}}>{extractedData && extractedData[currentQuestion].category}</Category>
                                    <QuestionText style={{direction: currentLanguageDir}}>{extractedData && extractedData[currentQuestion].question}</QuestionText>
                                </QuestionSection>
                                <AnswerSection style={{direction: currentLanguageDir}}>
                                        {`${extractedData[currentQuestion].option1}` && <AnswerBtn onClick={() => handleAnswerOptionClick(1, extractedData[currentQuestion].context, extractedData[currentQuestion].category, extractedData[currentQuestion].id)}>{extractedData[currentQuestion].option1}</AnswerBtn>}
                                        {`${extractedData[currentQuestion].option2}` && <AnswerBtn onClick={() => handleAnswerOptionClick(2, extractedData[currentQuestion].context, extractedData[currentQuestion].category, extractedData[currentQuestion].id)}>{extractedData[currentQuestion].option2}</AnswerBtn>}
                                        {`${extractedData[currentQuestion].option3}` && <AnswerBtn onClick={() => handleAnswerOptionClick(3, extractedData[currentQuestion].context, extractedData[currentQuestion].category, extractedData[currentQuestion].id)}>{extractedData[currentQuestion].option3}</AnswerBtn>}
                                        {`${extractedData[currentQuestion].option4}` && <AnswerBtn onClick={() => handleAnswerOptionClick(4, extractedData[currentQuestion].context, extractedData[currentQuestion].category, extractedData[currentQuestion].id)}>{extractedData[currentQuestion].option4}</AnswerBtn>}
                                        {`${extractedData[currentQuestion].option5}` && <AnswerBtn onClick={() => handleAnswerOptionClick(5, extractedData[currentQuestion].context, extractedData[currentQuestion].category, extractedData[currentQuestion].id)}>{extractedData[currentQuestion].option5}</AnswerBtn>}
                                </AnswerSection>
                            </FormContainer>
                        </>
                            )}
                </ContentBox>
            </QuestionAndAnswers>
        </LandingSection>
    )    
}

const LandingSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
`

const RightContainer = styled.div`
    display: flex;
    max-width: 800px;
    margin: 30px auto;
    position: relative;
    text-align: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
`

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin: 2rem 5rem;
    @media (max-width: 700px) {
        margin: 0px;
    }
`

const BackgroundGif = styled.img`
opacity: .5;
margin-top: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    position: absolute;
    z-index: -1;
    @media (max-width: 1200px) {
        width: 92%;
        height: 300px;
        margin: 1rem;
    }
    @media (max-width: 600px) {
        width: 350px;
        margin: 0px;
    }
`

const IntroBox = styled.div`
    max-width: 850px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ImgNumberContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`

const HeadingIntro = styled.h2`
    font-size: 2rem;
    margin: 1rem;
`
const H4 = styled.h4`
    color: white;
    font-size: .9rem;
`

const ParagraphIntro = styled.p`
    font-size: 1.2rem;
    text-align: center;
    margin: 1.5rem;
`

const ImgNumberBox = styled.div`
    position: relative;
    text-align: center;
    color: white;
    margin: 10px;
`
const TextIntro = styled.div`
`

const TextDiv = styled.div`
    position: absolute;
    text-shadow: 0 0 5px white;
    top: 26%;
    left: 55%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 12px;
    font-weight: 600;
`

const ImgNumber = styled.img`
    width: 150px;
    border: 20px solid white;
    border-radius: 1rem;
`

const H2 = styled.h2`
    color: white;
    font-size: 3rem;
    font-family: 'Hanalei Fill';
    letter-spacing: 1rem;
    text-shadow: 0 0 20px #FF0000, 0 0 10px lightgray;
    @media (max-width: 400px) {
        font-size: 2rem; 
    }
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 850px;
    margin: 39px;
    @media (max-width: 800px) {
        margin: 0 auto;
        flex-direction: row;
        height: 500px;
    }
`

const Span = styled.span`
    font-size: .9rem;
    letter-spacing: .5rem;
`

const P = styled.p`
    color: white;
    margin-bottom: -40px;
    font-weight: 700;
    @media (max-width: 400px) {
        margin-bottom: -20px; 
    }
`

const HeadingTextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
`

const FormBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 0 10px 0;
    @media (max-width: 400px) {
       width: 375px; 
    }
`

const Form = styled.form`
    display: flex;
    margin-top: -20px;
    flex-direction: column;
    padding: 1rem;
    border-radius: 10px;
    background-color: white;
    @media (max-width: 400px) {
        width: 330px; 
    }
`

const InputBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap-reverse;
`

const QuestionAndAnswers = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 400px;
    align-items: start;
    border-radius: 2rem;
    flex-wrap: wrap-reverse;
    margin-bottom: 5rem;
`


const ContentBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: white;
    margin-top: 1rem;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
`


const ResultSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  min-width: 300px;
  min-height: 300px;
  @media (max-width: 400px) {
      width: 360px
    }
`

const QuestionSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    height: auto;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: transform .4s ease-in-out;
    @media (max-width: 700px) {
        height: auto;
    }
`

const QuestionCount = styled.div`
    margin: 20px 10px 10px 10px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    span {
        font-size: 28px;
    }
`

const Category = styled.p`
    font-size: .8rem;
    margin-top: -15px;
    border-radius: 10px;
    color: #b1b3b1;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

const Context = styled.h4`
    margin-top: 0;
    color: black;
    background-color: lightgreen;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 1rem;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
`

const QuestionText = styled.div`
    padding: 10px;
    margin: 10px;
    font-size: 1.2rem;;
`

const AnswerSection = styled.div`
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 400px) {
        width: 360px;
    }
`

const AnswerBtn = styled.button`
    margin: 2% 0px;
    width: 380px;
    font-size: 16px;
    color: #ffffff;
    background-color: #3d3e41;
    border-radius: 15px;
    display: flex;
    padding: 5px;
    justify-content: center;
    border: 2px solid #828282;
    cursor: pointer;
    &:hover {
        background-color: #2d2d2d;
    }
    @media (max-width: 400px) {
        width: 360px;
    }
`

const GuideBox = styled.div`
    display: flex;
    justify-content: center;
    margin: 3rem;
    width: 410px;
    @media (max-width: 1316px) {
        margin: 2rem auto;
    }
`