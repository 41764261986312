import React from "react"
import { BrowserRouter as Router, useRoutes, BrowserRouter } from 'react-router-dom'
import HowItWorks from "./components/HowItWorks"
import Homepage from "./components/Homepage"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import i18n from './translation/i18n';
import HeaderV2 from './components/shared/Header';


const AllRoutes = () => { 
  const routes = useRoutes([
    { path:"/howitworks/*", element:<HowItWorks /> },
    { path:"/", element:<Homepage />}
  ])
  return routes
}

const AppWrapper = () => {

  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
      setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };

  React.useEffect(() => {
      handleClickOpen();
  }, [])

  return (
    <Router history={BrowserRouter}>
       <React.Fragment>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <LanguageBox>
                <DialogTitle id="alert-dialog-title">
                  Select your language: 
                  <div>
                    <Button
                        style={{ margin: '10px'}}
                        variant="contained"
                        type="submit" onClick={() => i18n.changeLanguage('en')}
                    > English
                    </Button>
                    <Button
                        style={{ margin: '10px'}}
                        variant="contained"
                        type="submit" onClick={() => i18n.changeLanguage('fa')}
                    > فارسی
                    </Button>
                    </div>
                </DialogTitle>
              </LanguageBox>
              <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center'}}>
                  {t('dialogAlert.dialogMessage')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus required>
                {t('dialogAlert.iAcknowledgeButton')}
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        <HeaderV2 />
          <AllRoutes />
    </Router>
  )
}

export default AppWrapper


const LanguageBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`