import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppWrapper from './Routes';
import './App.css';


function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const lng = navigator.language;
    i18n.changeLanguage(lng);
  }, []);

  return (
    <div className="App">
      <AppWrapper />
    </div>
  );
}

export default App;