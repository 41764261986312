import styled from "styled-components";


export default function HeatMap({results}) {

    let heatmap = [];

    function riskColorPicker(riskValue) {
        let assignedColor = '';

        if (riskValue >= -4 && riskValue <= -2) {
            assignedColor = '#75C500';
          } else if (riskValue > -2 && riskValue <= -0.5) {
            assignedColor = '#DFDC01';
          } else if (riskValue > -0.5 && riskValue <= 0.5) {
            assignedColor = '#FFAD01';
          } else if (riskValue > 0.5 && riskValue <= 2) {
            assignedColor = '#FF7500';
          } else if (riskValue > 2 && riskValue <= 4){
            assignedColor = '#FE0000';
          } else {
            assignedColor = 'blue';
          }
        return assignedColor;
    }


    function matrix(results) {
        let length = results.length;
        let ratio = [0, 0];

        if (length > 0 && length <= 4) {
            ratio = [2, 2];
        } else if (length > 4 && length <= 6) {
            ratio = [3, 2];
        } else if (length > 6 && length <= 9) {
            ratio = [3, 3];
        } else if (length > 9 && length <= 12) {
            ratio = [4, 3];
        } else if (length > 12 && length <= 16) {
            ratio = [4, 4];
        } else {
            ratio = [0, 0]
        }

        let sortedData = results;
        let counter = 0;
        let res = [];

            for (let row = 0; row < ratio[0]; row++) {
                let newRowWithUpdatedContext = [];
                for (let column = 0; column < ratio[1]; column++) {
                    if (sortedData[counter] === undefined) {
                        let currentContext = [-5 , '', [], 'gray'];
                        newRowWithUpdatedContext.push(currentContext)
                    } else {
                        let currentContext = sortedData[counter];
                        let riskValue = currentContext[0];
                        let riskColor = riskColorPicker(riskValue);
                        let updatedContext = [...currentContext, riskColor];
                        newRowWithUpdatedContext.push(updatedContext)
                    }
                    counter++;
                }
                res.push(newRowWithUpdatedContext);
            }
            return heatmap = res;
        }


    matrix(results);


        return (
            <Container>
                <HeatmapGrid> 
                    {heatmap && heatmap.map((row, i) => (
                        <Row key={i} style={{ animationDelay: `.${(i) * 2}s`}}>
                        {row.map((cell, j) => (
                            <Column style={{ background: cell[3] }} key={j}><ContextName>{cell[1]}</ContextName></Column>
                        ))}
                        </Row>
                    ))}
                    <ImpactArrowBox>
                        <OuterHeatmapText>Impact</OuterHeatmapText>
                        <Arrow>
                            <Line></Line>
                            <Point></Point>
                        </Arrow>
                    </ImpactArrowBox> 
                    <ProbabalityArrowBox>
                        <OuterHeatmapText>Likelihood</OuterHeatmapText>
                        <Arrow>
                            <Line></Line>
                            <Point></Point>
                        </Arrow>
                    </ProbabalityArrowBox> 
                </HeatmapGrid>
            </Container>
        );
}


const Container = styled.div`
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #8a8584;
    background-color: #4d4b4b;
    border-radius: 10px;
`

const ImpactArrowBox = styled.div`
    display: flex;
    position: absolute;
    bottom: -6px;
    right: 20px;
    rotate: 180deg;
    margin: 5px 0px;
`

const ProbabalityArrowBox = styled.div`
    display: flex;
    position: absolute;
    bottom: 90px;
    right: -70px;
    rotate: 270deg;
`

const Arrow = styled.div`
    rotate: 180deg;
    margin: 0 10px;
`

const Line = styled.div`
    margin-top: 5px;
    width: 70px;
    background: #dbdbdb;
    height: 2px;
    float: left;
`

const Point = styled.div`
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 12px solid #dbdbdb;
    rotate: 180deg;
`

const OuterHeatmapText = styled.h6`
    font-size: .8rem;
    color: #dbdbdb;
    margin: 0;
`

const HeatmapGrid = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    rotate: 90deg;
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    background: lightblue;
    justify-content: center;
    align-items: center;
    animation: fadeInAnimation ease .5s;
    @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    height: 75px;
    width: 75px;
    border: 1px solid black;
    rotate: -90deg;
`

const ContextName = styled.p`
    color: white;
    text-shadow: 0 0 3px black, 0 0 5px black;
    font-size: .8rem;
    font-weight: 600;
`