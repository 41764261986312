import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import HeatMap from './HeatMap';
import Button from '@mui/material/Button';


const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  }
});

export default function BasicTable({ results }) {

  const { t } = useTranslation();
  const [allData, setAllData] = useState(results);
  const [displayedData, setDisplayedData] = useState([]);


  useEffect(() => {
    let criticalContexts = [];
    for (let i = 0; i < allData.length; i++) {
      if (allData[i][0] > .5) {
        criticalContexts.push(allData[i]);
      }
      setDisplayedData(criticalContexts);
    }
  }, [])


  function displayAllData() {
    setDisplayedData(results)
  }


  function riskStatusIndicator(number) {
    if (number <= -2) {
      return <ColorBox><GreenDot></GreenDot>{t('table.acceptable')}</ColorBox>
    } else if (number > -2 && number <= -0.5) {
      return <ColorBox><YellowDot></YellowDot>{t('table.low')}</ColorBox>
    } else if (number > -0.5 && number <= 0.5) {
      return <ColorBox><OrangeDot></OrangeDot>{t('table.medium')}</ColorBox>
    } else if (number > 0.5 && number <= 2) {
      return <ColorBox><BlinkerHighColor></BlinkerHighColor>{t('table.high')}</ColorBox>
    } else if (number > 2 && number <= 4) {
      return <ColorBox><BlinkerCriticalColor></BlinkerCriticalColor>{t('table.extreme')}</ColorBox>
    } else {
      return <div>ERROR</div>
    }
  }


  function isValidHttpUrl(string) {
    let url;
    
    const content = string.split('**');
    const description = content[0];
    const newLink = content[1];

    try {
      url = new URL(newLink);
    } catch (_) {
      return <TableCell align="center">{string}</TableCell>
    }
    if (url.protocol === "http:" || url.protocol === "https:") {
      return <TableCell style={{ width: '100%' }} align="center"><ALinkInTable href={newLink} target="_blank">{description}</ALinkInTable></TableCell>
    }
  }


  return (
    <ThemeProvider theme={darkTheme}>
      <TableContainer component={Paper} style={{ margin: '10px 0'}}>
        <h3>Results & Recommendations</h3>
        <HeatMap results={results}/>
        <Table aria-label="simple table">
          <TableHead>
              <TableRow> 
                <TableCell align="center">{t('table.riskTableHeading')}</TableCell>
                <TableCell align="center">{t('table.contextTableHeading')}</TableCell>
                <TableCell align="center">{t('table.adviceTableHeading')}</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
            {displayedData && displayedData.map((row, i) => (
              <TableRow
                style={{ animationDelay: `.${(i)}s`}}
                key={row[1]}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row" style={{width: '100px'}} >
                  {riskStatusIndicator(row[0])}
                </TableCell>
                <TableCell align="center">{row[1]}</TableCell>
                <AdviceBox>
                  {isValidHttpUrl(row[2][0])}
                </AdviceBox>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      <Button variant="contained" onClick={() => displayAllData()} disabled={results.length === displayedData.length }>Display All Results</Button>
      </TableContainer>
    </ThemeProvider>
  );
}


const AdviceBox = styled.div`
  width: 100%;
`

const BlinkerCriticalColor = styled.div`
  display: flex;
  margin: 0 10px;
  background-color: red;
  padding: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  @keyframes choppyBlink {
    from { opacity: .2; }
    to { opacity: 1; }
  }
  @keyframes blink {
    0%, 100% { opacity: 0; } /* more concise! */
    50% { opacity: 1; }
  }
  animation: blink 1.5s infinite;
  -webkit-animation: blink 1.5s infinite;
  -moz-animation: blink 1.5s infinite;
  -o-animation: blink 1.5s infinite;
  animation: blink 1.5s infinite;
`

const BlinkerHighColor = styled.div`
  display: flex;
  margin: 0 10px;
  background-color: #d6621e;
  padding: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  @keyframes choppyBlink {
    from { opacity: .2; }
    to { opacity: 1; }
  }
    @keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }
  animation: blink 1.5s infinite;
  -webkit-animation: blink 1.5s infinite;
  -moz-animation: blink 1.5s infinite;
  -o-animation: blink 1.5s infinite;
  animation: blink 1.5s infinite;
`

const ColorBox = styled.div`
  display: flex;
  flex-direction: row;
`

const OrangeDot = styled.div`
  display: flex;
  margin: 0 10px;
  padding: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #FFAD01;
`

const YellowDot = styled.div`
  display: flex;
  margin: 0 10px;
  padding: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #DFDC01;
`

const GreenDot = styled.div`
  display: flex;
  margin: 0 10px;
  background-color: red;
  padding: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #03fc07;
`

const ALinkInTable = styled.a`
  color: white;
`
