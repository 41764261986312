
import React from 'react';
import styled from 'styled-components';
import guruPicture from '../assets/small-size-guru.png';
import Handcuff from '../assets/handcuff-img.svg';
import Laptop from '../assets/laptop-security-img.png';
import Smartphone from '../assets/smartphone-security.svg';
import CloudSecurity from '../assets/cloud-security-img.svg';
import Protestors from '../assets/people-protest-img.png';
import Privacy from '../assets/people-privacy-img.png';



export default function Heading(){

    return (
        <Container>
                <ImageBox3>
                    <HandcuffPicture src={Handcuff} />
                </ImageBox3>
                <ImageBox4>
                    <ProtestorsPicture src={Protestors} />
                </ImageBox4>
                <ImageBox2>
                    <PrivacyPicture src={Privacy} />
                </ImageBox2>
                <ImageBox>
                    <GuruPicture src={guruPicture} />
                </ImageBox>
                <ImageBox7>
                    <SmartphonePicture src={Smartphone} />
                </ImageBox7>
                <ImageBox6>
                    <LaptopPicture src={Laptop} />
                </ImageBox6>
                <ImageBox8>
                    <CloudSecurityPicture src={CloudSecurity} /> 
                </ImageBox8>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    width: 100%;
    border: 1px solid gray;
    border-radius: 2rem;
    height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Helvetica', sans-serif;
	background: rgb(26, 188, 156);
	background: -moz-linear-gradient(-45deg, rgba(26, 188, 156, 1) 0%, rgba(142, 68, 173, 1) 100%);
	background: -webkit-linear-gradient(-45deg, rgba(26, 188, 156, 1) 0%, rgba(142, 68, 173, 1) 100%);
	background: linear-gradient(135deg, rgba(26, 188, 156, 1) 0%, rgba(142, 68, 173, 1) 100%);
`

const ImageBox = styled.div`
    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
        50% {
            box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
            transform: translatey(-20px);
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
	height: 200px;
	box-sizing: border-box;
	border-radius: 50%;
	box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
    @media (max-width: 1000px) {
        width: 200px;
        height: 200px;
    }
    @media (max-width: 600px) {
        width: 150px;
        height: 150px;
    }
`

const GuruPicture = styled.img`
    width: 100%;
    height: auto;
    border: 5px solid orange;
    border-radius: 50%; 
    @media (max-width: 1000px) {
        width: 150px;
        height: 150px;
    }
    @media (max-width: 600px) {
        width: 150px;
        height: 150px;
    }
`

const ImageBox2 = styled.div`
    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
        50% {
            box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
            transform: translatey(-30px);
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
	height: 150px;
	box-sizing: border-box;
	border-radius: 50%;
	box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
    @media (max-width: 1000px) {
        width: 100px;
        height: 100px;
    }
    @media (max-width: 600px) {
        width: 80px;
        height: 80px;
    }
    @media (max-width: 450px) {
        width: 50px;
        height: 50px;
    }
`

const PrivacyPicture = styled.img`
    width: 100%;
    height: auto;
    border: 5px solid orange;
    border-radius: 50%;
    background-color: white;
    @media (max-width: 1000px) {
        width: 120px;
        height: 120px;
    }
    @media (max-width: 600px) {
        width: 80px;
        height: 80px;
    }
    @media (max-width: 450px) {
        width: 50px;
        height: 50px;
    }
`

const LaptopPicture = styled.img`
    width: 100%;
    height: auto;
    border: 5px solid orange;
    border-radius: 50%; 
    @media (max-width: 1000px) {
        width: 120px;
        height: 120px;
    }
    @media (max-width: 600px) {
        width: 80px;
        height: 80px;
    }
    @media (max-width: 450px) {
        width: 50px;
        height: 50px;
    }
`

const ImageBox3 = styled.div`
    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
        50% {
            box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
            transform: translatey(-20px);
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
	height: 120px;
	box-sizing: border-box;
	border-radius: 50%;
	animation: float 5s ease-in-out infinite;
    @media (max-width: 1000px) {
        width: 90px;
        height: 90px;
        box-shadow: 0px;
    }
    @media (max-width: 600px) {
        width: 30px;
        height: 30px;
    }
`

const HandcuffPicture = styled.img`
    width: 100%;
    height: auto;
    border: 5px solid orange;
    border-radius: 50%;
    background-color: red;
    @media (max-width: 1000px) {
        width: 80px;
        height: 80px;
        box-shadow: 0px;
    }
    @media (max-width: 600px) {
        width: 30px;
        height: 30px;
    }
`

const ImageBox4 = styled.div`
    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
        50% {
            box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
            transform: translatey(-30px);
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
	height: 130px;
	box-sizing: border-box;
	border-radius: 50%;
	box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
	transform: translatey(0px);
	animation: float 4s ease-in-out infinite;
    @media (max-width: 1000px) {
        display: none;
        justify-content: end;
        align-items: flex-end;
        width: 100px;
        height: 100px;
    }
`

const ProtestorsPicture = styled.img`
    width: 100%;
    height: auto;
    background-color: lightblue;
    border: 5px solid orange;
    border-radius: 50%;  
    @media (max-width: 1000px) {
        width: 30px;
        height: 30px;
    }
`

const ImageBox6 = styled.div`
    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
        50% {
            box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
            transform: translatey(-20px);
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
	height: 130px;
	box-sizing: border-box;
	border-radius: 50%;
	box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
	transform: translatey(0px);
	animation: float 5.5s ease-in-out infinite;
    @media (max-width: 1000px) {
        display: none;
        justify-content: end;
        align-items: flex-end;
        width: 100px;
        height: 100px;
    }
`

const ImageBox7 = styled.div`
    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
        50% {
            box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
            transform: translatey(-20px);
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
	height: 150px;
	box-sizing: border-box;
	border-radius: 50%;
	box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
	transform: translatey(0px);
	animation: float 5s ease-in-out infinite;
    @media (max-width: 1000px) {
        width: 100px;
        height: 100px;
    }
    @media (max-width: 600px) {
        width: 80px;
        height: 80px;
    }
    @media (max-width: 450px) {
        width: 50px;
        height: 50px;
    }
`

const SmartphonePicture = styled.img`
    width: 100%;
    height: auto;
    border: 5px solid orange;
    border-radius: 50%; 
    @media (max-width: 1000px) {
        width: 120px;
        height: 120px;
    }
    @media (max-width: 600px) {
        width: 80px;
        height: 80px;
    }
    @media (max-width: 450px) {
        width: 50px;
        height: 50px;
    }
`

const ImageBox8 = styled.div`
    @keyframes float {
        0% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
        50% {
            box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
            transform: translatey(-20px);
        }
        100% {
            box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
            transform: translatey(0px);
        }
    }
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
	height: 120px;
	box-sizing: border-box;
	border-radius: 50%;
	animation: float 6s ease-in-out infinite;
    @media (max-width: 1000px) {
        width: 50px;
        height: 50px;
        box-shadow: 0px;
    }
    @media (max-width: 600px) {
        width: 30px;
        height: 30px;
    }
`

const CloudSecurityPicture = styled.img`
    width: 100%;
    height: auto;
    border: 5px solid orange;
    background-color: white;
    border-radius: 50%;  
    @media (max-width: 1000px) {
        width: 80px;
        height: 80px;
    }
    @media (max-width: 600px) {
        width: 30px;
        height: 30px;
    }
`
