import React, { useState } from "react";
import styled from 'styled-components';
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from '../../assets/gif-logo.gif';
import i18n from '../../translation/i18n';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';

//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";


export default function MainNavigation() {

    const lngs = {
        en: { nativeName: 'English'},
        fa: { nativeName: 'Persian'}
      }

    /*
    react useState hook to save the current open/close state of the drawer,
    normally variables dissapear afte the function was executed
    */
    const [open, setState] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { t } = useTranslation();
    const openLang = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    /*
    function that is being called every time the drawer should open or close,
    the keys tab and shift are excluded so the user can focus between
    the elements with the keys
    */
    const toggleDrawer = (open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      //changes the function state according to the value of open
      setState(open);
    };


  return (
    <AppBar position="static">
        <Boxes  sx={{ 
            mr: 2,
            display: {
                xs: 'none',
                sm: 'block',
            }
            }}>
                <Logo src={logo} type="video/mp4" />
                <Items>
                    <Item to='/' >{t('navigationMenu.home')}</Item>
                    <Item to='/howitworks' >{t('navigationMenu.howItWorks')}</Item>
                    <LanguageBox>
                        <Button
                            id="demo-positioned-button"
                            aria-controls={openLang ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openLang ? 'true' : undefined}
                            onClick={handleClick}
                            variant="contained"
                        >
                            <LanguageTwoToneIcon />
                        </Button>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={openLang}
                            onClose={handleClose}
                            anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                            transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                            }}
                        >
                            {Object.keys(lngs).map((lng) => (
                                <div key={lng} onClick={handleClose}>
                                    <MenuItem type="submit" onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng }>{lngs[lng].nativeName}</MenuItem>
                                </div>
                            ))}
                        </Menu>
                    </LanguageBox>
                </Items>
            <IconButton 
                edge="end" 
                color="inherit" 
                aria-label="open drawer" 
                onClick={toggleDrawer(true)}
                sx={{ 
                    mr: 2,
                    display: {
                    xs: 'block',
                    sm: 'none',
                    }
                }}
            >
                <MenuIcon />
            </IconButton>
        </Boxes>


        {/* The outside of the drawer */}
        <Drawer
            //from which side the drawer slides in
            anchor="right"
            //if open is true --> drawer is shown
            open={open}
            //function that is called when the drawer should close
            onClose={toggleDrawer(false)}
            //function that is called when the drawer should open
            onOpen={toggleDrawer(true)}
        >
            {/* The inside of the drawer */}
            <Box sx={{
                p: 2,
                height: 1,
                backgroundColor: "#3e403e",
            }}>

                {/* 
                when clicking the icon it calls the function toggleDrawer 
                and closes the drawer by setting the variable open to false
                */}
                <IconButton sx={{mb: 2, color: 'white'}}>
                <CloseIcon onClick={toggleDrawer(false)} />
                </IconButton>

                <Divider sx={{mb: 2}} />

                <Box sx={{mb: 2}}>
                <ListItemButton>
                    <Item to='/' onClick={toggleDrawer(false)} >{t('navigationMenu.home')}</Item>
                </ListItemButton>
                <ListItemButton>
                    <Item to='/howitworks' onClick={toggleDrawer(false)}>{t('navigationMenu.howItWorks')}</Item>
                </ListItemButton>
                <ListItemButton>
                <LanguageBox>
                    <Button
                        id="demo-positioned-button"
                        aria-controls={openLang ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openLang ? 'true' : undefined}
                        onClick={handleClick}
                        variant="contained"
                    >
                        <LanguageTwoToneIcon />
                    </Button>
                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={openLang}
                        onClose={handleClose}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                        }}
                    >
                        {Object.keys(lngs).map((lng) => (
                            <div key={lng} onClick={handleClose}>
                                <MenuItem type="submit" onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng }>{lngs[lng].nativeName}</MenuItem>
                            </div>
                        ))}
                    </Menu>
                    </LanguageBox>
                </ListItemButton>
                </Box>
                    <Box sx={{
                    display: "flex", 
                    justifyContent:"center", 
                    position: "absolute", 
                    bottom: "0", 
                    left: "50%", 
                    transform: "translate(-50%, 0)"}}
                    >
                    </Box>
            </Box>
        </Drawer>
    </AppBar>

  );
}


const Boxes = styled.div`
    display: flex;
    justify-content: space-between;
    background-color: black;
    border-bottom: 1px solid gray;
`

const Logo = styled.img`
    height: 55px;
    /* width: 50px; */
    margin-left: 1.5rem;
    padding: 2px;
    border: 1px solid white;
    border-radius: 50%;
    margin: 0px 10px;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
  }
`

const LanguageBox = styled.div`
    /* margin-top: 5px; */
`

const Items = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    margin-right: 1.5rem;
    @media (max-width: 600px) {
        display: none;
    }
`

const Item = styled(Link)`
    text-decoration: none;
    font-weight: 600;
    color: #bfbfbf;
    &:hover {
        text-shadow: 0px 0px 5px black;
        color: white;
        transition: .5s;
    }
    @media only screen and (max-width: 600px) {
        margin-bottom: 30%;
    }
`
