
import * as React from 'react';
import styled from 'styled-components';
import InputAndQuestionForm from './InputAndQuestionForm';
import Heading from './Heading';



export default function Homepage(){

    return (
        <Container>
            <Box>
                <Heading />
            </Box>
            <FormBox>
                <InputAndQuestionForm />
            </FormBox>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const Box = styled.div`
    display: flex;
    margin-top: 30px;
    justify-content: center;
    color: white;
`

const FormBox = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media (max-width: 1000px) {
        flex-direction: column;
    }
`