import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Arrow from '../assets/arrow-down.png';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';


export default function Resources() {

    const [open, setOpen] = useState('none');
    const [currentCategory, setCurrentCategory] = useState();


    useEffect(() => {
        // Fetching the URL for Educational Sheet 
        const loadPost = async () => {
            const base = `https://docs.google.com/spreadsheets/d/1CsXlZYfyX8RpNXLRSX6cUEcBxm3m7RkdDWgty2omgpU/gviz/tq?`;
            const query = encodeURIComponent('Select *');
            const url = `${base}&sheet=&tq=${query}`;
            const data = [];
            await axios.get(url)
            .then(res => dataHelper(res.data))

            function dataHelper(newData) {
                const jsData = JSON.parse(newData.slice(47,-2));
                const colz = [];
                jsData.table.cols.forEach((heading)=>{
                    if(heading.label) {
                    colz.push(heading.label.toLowerCase().replace(/\s/g,''));
                    }
                })
                jsData.table.rows.forEach((main)=>{
                    const row = {};
                    colz.forEach((ele,ind)=>{
                        row[ele] = (main.c[ind] != null) ? main.c[ind].v : '';
                    })
                    data.push(row);
                    setCurrentCategory(data)
                })}
        }

        loadPost();
    }, [])

    return (
        <Accordion onClick={() => setOpen(!open)}>
            <AccordionSummary>
                <CategoryTitle>
                    <CategoryName>User Guide</CategoryName>
                    <CategoryLanguage>(En & Fa)</CategoryLanguage>
                </CategoryTitle>
                <AccordionArrow open={open}  src={Arrow} alt='Accordion Arrow' />
            </AccordionSummary>
            <MoreDetails open={open}>
                <ContentLinkBox>
                    {currentCategory && currentCategory.map((item) => {
                        return (
                            <LinkBox key={item.id}>
                                <Title>{item.name}</Title>
                                <ItemBox>
                                    <Tooltip disableFocusListener title={item.info}>
                                        <InfoIcon variant="outlined" style={{ width: '50px' }} ></InfoIcon>
                                    </Tooltip>
                                    <Btn variant="contained" size="small"><ALink href={item.link} target="_blank">Open</ALink></Btn>
                                </ItemBox>
                            </LinkBox>
                        )
                    })}
                </ContentLinkBox>
            </MoreDetails>
        </Accordion>
    )
}

const Accordion = styled(MuiAccordion)`
    margin-bottom: 1.2rem;
    width: 380px;
`
const ItemBox = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Btn = styled.button`
    padding: 5px;
    margin: 5px;
    min-height: 2rem;

`
const ALink = styled.a`
    text-decoration: none;
`

const AccordionSummary = styled(MuiAccordionSummary)`
    height: 60px;
    min-height: 60px;
    box-shadow: 2px 2px 8px gray;
    @media only screen and (max-width: 500px) {
        padding: 0 !important;
    }
    > div {
        background-color: red;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        min-height: 60px;
        font-size: 2rem;
        background: white;
        cursor: pointer;
    }
`

const MoreDetails = styled(MuiAccordionDetails)`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    background: #ffffff;
    border-top: none;
    border-radius: 1rem;
`

const CategoryTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const CategoryName = styled.p`
    font-weight: 700;
    color: #707070;
    font-size: 1rem;
    margin-left: .8rem;
    margin-right: .8rem;
`

const CategoryLanguage = styled.p`
    font-size: 1rem;
    color: #707070;
    @media only screen and (max-width: 400px) {
        font-size: .7rem;
    }
`
const AccordionArrow = styled.img`
    border: 2px solid #FC5600;
    padding: 8px;
    border-radius: 50%;
    margin: 5px;
    text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
    transition: transform .4s ease-in-out;
`

const Title = styled.h4`
`

const LinkBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ContentLinkBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 380px;
    @media (max-width: 400px) {
        margin: 0;
    }
`