import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';



export default function HowItWorks(){

    const { t } = useTranslation();
  
    return (
            <Container>
                <H2>{t('howItWorks.howItWorksHeading')}</H2>
                <LinkBox>
                    <H4>USER GUIDE</H4>
                    <Span>(English)</Span>
                    <Paragraph>This guide is for anyone who wants to assess their risk with a Google-sheets link</Paragraph>
                    <ALink target='_blank' rel='noopener noreferrer' href='https://tome.app/test-notif/user-guide-final-cls42g9xj01xymp650099cn6y'><Button variant="contained">Instruction</Button></ALink>
                </LinkBox>
                <LinkBox>
                    <H4>MENTOR GUIDE</H4>
                    <Span>(English)</Span>
                    <Paragraph>This guide is for anyone who wants to design a Form with question and answers to help users assess their risks</Paragraph>
                    <ALink target='_blank' rel='noopener noreferrer' href='https://tome.app/test-notif/mentor-guide-clsawyz260267oe6brisgr6ac'><Button variant="contained">Instruction</Button></ALink>
                </LinkBox>
            </Container>
        )
    }
    
    const Container = styled.div`
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `
    const LinkBox = styled.div`
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid gray;
        border-radius: 1rem;
    `

    const Paragraph = styled.div`
        width: 350px;
        color: white;
        margin: 1rem;
    `

    const H2 = styled.h2`
        font-size: 2rem;
        color: white;
        text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
        border: 4px solid black;
        border-radius: 1rem;
    `

    const H4 = styled.h4`
        font-size: 1.5rem;
        color: white;
        text-shadow: 0 0 3px black, 0 0 3px black, 0 0 3px black, 0 0 3px black;
    `
    
    const ALink = styled.a`
        text-decoration: none;
        font-size: 1rem;
        font-weight: 600;
        margin: 1rem;
    `

    const Span = styled.span`
        font-size: .9rem;
        color: white;
        margin-top: -2rem;
    `