import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
          navigationMenu: {
              home: "HOME",
              howItWorks: "HOW IT WORKS",
          },
          inputAndQuestionForm: {
            assessYourRisk: "ASSESS YOUR RISK",
            InputPlaceholder: "Insert Google Sheet Link",
            fileAccessPermissionGuide: "File Access: Anyone on the internet with the link can view",
            submitButton: "start",
            useOurAssessmentMsg: "Don't have a link? Use our assessment links below",
            resetButton: "Reset",
            allDataWillWillBeLostMsg: "All data and results will be lost.",
            areYouSureMsg: "Are you sure?",
          },
          table: {
            acceptable: "Acceptable",
            low: "Low",
            medium: "Medium",
            high: "High",
            extreme: "Extreme",
            riskTableHeading: "Risk",
            contextTableHeading: "Context",
            adviceTableHeading: "Advice",
          },
          dialogAlert: {
            dialogMessage: 'We do not collect or store any of your personal data. Processing data and results takes place in your browser. To ensure complete data removal, simply refresh the page.',
            iAcknowledgeButton: 'I Acknowledge',
          },
          introduction: {
            fourEasyStepsHeading: "Assess Your Risk In 4 Easy Steps.",
            introductionDetails: "My Risk Guru is an assessment tool designed to help users evaluate various risks through a series of Q&A. It provides advice to mitigate the risks and presents them in a heatmap to prioritize them based on their severity.",
          },
          howItWorks: {
            howItWorksHeading: "How it works",
          },
        },
      },
      fa: {
        translation: {
            navigationMenu: {
                home: "صفحه اصلی",
                howItWorks: "راهنمای کاربری"
            },
            inputAndQuestionForm: {
                assessYourRisk: "ریسک خود را ارزیابی کنید",
                InputPlaceholder: "لینک گوگل شیت را اینجا وارد کنید",
                fileAccessPermissionGuide: "Anyone on the internet with the link can view :دسترسی به فایل ",
                submitButton: "شروع",
                useOurAssessmentMsg: "از لینک ارزیابی ما در زیر استفاده کنید",
                resetButton: "تنظیم مجدد",
                allDataWillWillBeLostMsg: "تمام داده ها و نتایج از بین خواهند رفت",
                areYouSureMsg: "آیا مطمئن هستید؟",
              },
              table: {
                acceptable: "مناسب",
                low: "کم",
                medium: "متوسط",
                high: "زیاد",
                extreme: "خیلی زیاد",
                riskTableHeading: "ریسک",
                contextTableHeading: "موضوع",
                adviceTableHeading: "پیشنهادات",
              },
              dialogAlert: {
                dialogMessage: 'هیچ گونه داده شخصی از شما جمع‌آوری یا ذخیره نمی‌شود. همه محاسبات در همان مرورگر شما انجام می‌شود. برای حذف کامل داده‌ها، فقط کافیست صفحه را دوباره بارگذاری (ریفرش﴾  کنید',
                iAcknowledgeButton: 'تصدیق می کنم',
              },
              introduction: {
                fourEasyStepsHeading: "ریسک خود را در چهار مرحله آسان ارزیابی کنید",
                introductionDetails: "راهنمای ریسک یک ابزار ارزیابی است که برای کمک به کاربران در ارزیابی ریسک های مختلف از طریق یک سری پرسش و پاسخ طراحی شده است. توصیه هایی برای کاهش خطرات ارائه می دهد و آنها را در یک نقشه حرارتی برای اولویت بندی آنها بر اساس شدت آنها ارائه می دهد",
              },
              howItWorks: {
                howItWorksHeading: "راهنمای کاربری",
              },
          },
      }
    }
  });

export default i18n;